<template>
  <ElCollapse v-bind="$attrs" class="f-collapse" :suffix-icon="suffixIcon">
    <template v-for="(el, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </ElCollapse>
</template>

<script>
import 'element-plus/theme-chalk/el-collapse.css';
import { ElCollapse } from 'element-plus';
import { defineComponent, h, shallowRef } from 'vue';

import AppIcon from '@/components/Icon/AppIcon.vue';

export default defineComponent({
  name: 'FCollapse',
  components: {
    ElCollapse,
  },
  setup() {
    const suffixIcon = shallowRef({
      render() {
        return h(AppIcon, {
          name: 'warning',
          size: '18px',
        });
      },
    });

    return { suffixIcon };
  },
});
</script>

<style scoped lang="scss">
.f-collapse {
  background: var(--color-white);
  border: 1px solid var(--color-black-01);
  border-radius: 8px;
  overflow: hidden;
  min-height: 60px;

  :deep(.el-collapse-item:last-child) {
    .el-collapse-item__wrap {
      border-bottom: unset;
    }
  }
}
</style>
