<template>
  <ElButton
    v-bind="$attrs"
    :type="customType"
    :size="customSize"
    :class="{
      'is-full-width': isFullWidth,
      'is-text-full-width': isTextFullWidth,
      'no-margin': noMargin,
      'is-icon': isIcon,
      'has-slot-data': hasSlotData,
      'is-lowercased': isLowerCased,
      'has-font-medium': !noMedium,

      'icon-position-left': iconPosition === 'left',
      'icon-position-right': iconPosition === 'right',
      ...customClasses,
    }"
    :name="name || icon"
    :aria-label="name || icon"
    @click="onClick"
  >
    <AppIcon
      v-if="icon && !$attrs?.loading"
      :is-img-tag="isImgTag"
      :name="icon"
      :size="iconSize"
    />
    <slot v-for="(el, slot) in $slots" :name="slot" />
  </ElButton>
</template>

<script>
import 'element-plus/theme-chalk/el-button.css';
import { ElButton } from 'element-plus';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';

import { router } from '@/router';

const buttonTypes = [
  'plain',
  'primary',
  'text',
  'black',
  'black-transparent',
  'transparent',
  'danger',
  'white',
  'inner',
  'secondary',
  'black-danger',
  'success',
];

export default defineComponent({
  components: {
    ElButton,
  },
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isTextFullWidth: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return [...buttonTypes, ''].includes(value);
      },
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    isLowerCased: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, undefined],
      default: undefined,
    },
    href: {
      type: [String, undefined],
      default: undefined,
    },
    size: {
      type: String,
      default: 'default',
    },
    iconSize: {
      type: String,
      default: '18px',
    },
    isStopPropagation: {
      type: Boolean,
      default: false,
    },
    noMedium: {
      type: Boolean,
      default: false,
    },
    isImgTag: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { slots, emit }) {
    const hasSlotData = ref(false);

    onMounted(() => {
      if (slots.default) {
        hasSlotData.value = true;
      }
    });

    const onClick = (event) => {
      if (props.isStopPropagation) {
        event.stopPropagation();
      }
      emit('click');
      if (props.to) {
        router.push(props.to);
      }
      if (props.href) {
        const isMailTo = props.href.includes('mailto:');
        window.open(props.href, isMailTo ? '_self' : '_blank').focus();
      }
    };

    const customTypes = [...buttonTypes, ''];
    const customSizes = ['x-small'];

    const customType = computed(() => (customTypes.includes(props.type) ? '' : props.type));
    const customSize = computed(() => (customSizes.includes(props.size) ? null : props.size));

    const customClasses = computed(() => ({
      [`el-button--${props.type}`]: customType.value === '',
      [`el-button--${props.size}`]: customSize.value === null,
    }));

    const iconPosition = computed(() => {
      if (props.icon && !props.isIcon) {
        return 'left';
      }
      if (slots.default && Array.isArray(slots.default())) {
        const iconIndex = slots.default().findIndex((slot) => slot.type.name === 'AppIcon');
        if (iconIndex === 0) {
          return 'left';
        }
        if (iconIndex === slots.default().length - 1) {
          return 'right';
        }
      }
      return null;
    });

    return {
      hasSlotData,
      onClick,

      customType,
      customSize,
      customClasses,

      iconPosition,
    };
  },
});
</script>

<style scoped lang="scss">
$primary: map-get($colors, "primary");
$black: map-get($colors, "black");
$white: map-get($colors, "white");
$disabled: map-get($colors, "disabled");
$danger: map-get($colors, "danger");

.el-button {
  border: unset;
  height: 44px;
  line-height: 1.2;
  font-weight: 400;
  @include prevent-selection;
  @include focus-visible;

  > :deep(span) {
    font-size: 13px;
  }
}

.el-button {
  line-height: 1.35;
  display: flex;
  align-items: center;
  &--large {
    font-size: 16px;
    padding: 0 30px;
    &.is-icon {
      height: 44px;
      width: 44px;
      padding: 0;
    }
  }
  &--default {
    font-size: 13px;
    padding: 0 30px;
    span {
      font-size: 13px;
    }

    &.icon-position-left {
      padding-left: 20px !important;
      :deep(.icon) {
        margin-right: 12px;
      }
    }
    &.icon-position-right {
      padding-right: 20px;
      :deep(.icon) {
        margin-right: 0 !important;
        margin-left: 20px;
      }
    }
  }
  &--small {
    font-size: 13px;
    height: 40px;
    padding: 0 20px;

    &.icon-position-left {
      padding-left: 16px !important;
      :deep(.icon) {
        margin-right: 12px;
      }
    }
    &.icon-position-right {
      padding-right: 16px;
      :deep(.icon) {
        margin-right: 0 !important;
        margin-left: 16px;
      }
    }
  }
  &--x-small {
    height: 36px;
    padding: 0 20px;

    &.is-icon {
      width: 36px;
      height: 36px;
      :deep(svg) {
        width: 16px;
      }
    }

    &.icon-position-left {
      padding-left: 16px !important;
      :deep(.icon) {
        margin-right: 8px;
      }
    }
    &.icon-position-right {
      padding-right: 16px;
      :deep(.icon) {
        margin-right: 0 !important;
        margin-left: 12px;
      }
    }
  }

  &.has-font-medium > :deep(span) {
    @include font-medium;
  }
}

.el-button {

  @include transition-base ('background, border-color');
  &--plain {
    color: $black;
    background: #f7f7f7;
    // &:focus {
    //   color: $black;
    //   background: #f7f7f7;
    // }
    &:hover {
      background: #ECECEC
    }
    &:active {
      background: #d5d5d5;
      color: $black;
    }
  }
  &--primary {
    color: $white;
    background: $primary;

    &:hover {
      background: darken($primary, 8%);
    }
    &:active {
      background: darken($primary, 14%);
    }
  }
  &--secondary {
    color: var(--color-primary);
    background: var(--color-primary-01);
    border: 1px solid var(--color-primary);

    &:hover {
      background: var(--color-primary-015);
    }
  }
  &--black {
    color: $white;
    background: $black;

    &:hover {
      background: #333;
    }
    &:active {
      background: #4C4C4C;
    }
  }
  &--white {
    color: var(--color-primary);
    background: white;
    border: 1px solid var(--color-primary);

    &:hover,
    &:active {
      color: white;
      background: var(--color-primary);
    }
  }
  &--text {
    color: var(--color-black);
    background: unset;

    border: 1px solid var(--color-black-01);
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
      color: var(--color-black);
      background: unset;
      border: 1px solid var(--color-black-01);
      box-sizing: border-box;
      border-radius: 4px;
    }

    &:hover {
      background: var(--color-black-003) !important;
      color: var(--color-black);
      border-color: var(--color-black-02);
    }
    &:active {
      background: #e0e0e0 !important;
      color: var(--color-black);
    }
    &.is-disabled {
      border: 1px solid #E8E9ED;

      &:hover {
        background: transparent !important;
      }
    }
  }
  &--transparent {
    background: rgba(0, 0, 0, 0);
    &:hover {
      background: var(--color-white-02);
    }
  }
  &--danger {
    color: $white;
    background: $danger;

    &:hover {
      background: darken($danger, 8%);
    }
    &:active {
      background: darken($danger, 14%);
    }
  }
  &--black-danger {
    border: 1px solid transparent;
    background: var(--color-black-003);
    color: var(--color-black);

    :deep(.icon path) {
      fill: var(--color-black);
      @include transition-base('stroke');
    }

    &:hover {
      color: var(--color-error);
      border-color: var(--color-error);
      background: var(--color-error-005);

      :deep(.icon path) {
        fill: var(--color-error);
      }
    }
  }
  &--black-transparent {
    background: unset;
    color: var(--color-black);
    border: 1px solid var(--color-black-01);

    &:hover {
      color: var(--color-black);
      background: var(--color-black-003);
    }
  }
  &--inner {
    background-color: transparent;
    padding: 0px 8px;

    position: relative;

    > :deep(span) {
      color: var(--color-primary);
      @include font-medium;
    }
    &:hover {
      background-color: transparent;
      > :deep(span) {
        color: var(--color-primary);
      }
    }

    &.is-disabled {
      background-color: transparent !important;
      &:hover {
        background: transparent !important;

        > :deep(span) {
          color: var(--color-black-04);
        }
      }
    }
  }
}

.el-button {
  &.is-circle {
    padding: 13px;
  }
}

.is-full-width {
  width: 100%;
  margin-left: 0;

  &.is-text-full-width {
    :deep(span) {
      width: 100%;
    }
  }
}

.no-margin {
  margin-bottom: 0;
}

.is-icon {
  height: 32px;
  width: 32px;
  padding: 0;
  > :deep(span) {
    font-size: 13px;
    margin-left: 0px;
    transition: unset;
  }
}

.is-disabled {
  border: none;
  background-color: $disabled;
  color: var(--color-black-02) !important;
  // box-shadow: none;
  border-color: rgba(0, 0, 0, 0.2)!important;
  &:hover {
    color: var(--color-black-02) !important;
    background: $disabled !important;
  }
  :deep(.icon path) {
    fill: #c2c2c2 !important;
  }
}

.is-lowercased {
  :deep(span) {
    margin-top: -1px;
  }
}
</style>
