<template>
  <div class="f-dropdown" :class="{ 'no-styles': noStyles }">
    <ElDropdown
      v-bind="$attrs"
      ref="dropdown"
      trigger="click"
      :hide-on-click="hideOnClick"
      :popper-class="popperClass"
      :show-timeout="0"
      :hide-timeout="0"
      :popper-options="{
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        }],
      }"
      @visible-change="onVisibleChange"
    >
      <div class="f-dropdown--title" :class="{ small: type === 'small' }">
        <slot name="title">
          Title here
        </slot>

        <AppIcon
          v-if="showIndicator"
          name="arrow-dropdown"
          size="18px"
          color="rgba(0, 0, 0, 1)"
          is-img-tag
          :class="isDropdownOpen ? 'arrow-active' : 'arrow-inactive'"
        />
      </div>
      <template #dropdown>
        <div class="handler" @click="onHandleDropdown">
          <slot name="content" />
        </div>
      </template>
    </ElDropdown>
  </div>
</template>

<script>
import 'element-plus/theme-chalk/el-dropdown.css';
import { ElDropdown } from 'element-plus';
import { computed, defineComponent, ref } from 'vue';

import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';

export default defineComponent({
  name: 'FDropdown',
  components: {
    ElDropdown,
  },
  props: {
    blockClass: {
      type: String,
      default: '',
    },
    hideOnClick: {
      type: Boolean,
      default: true,
    },
    noStyles: {
      type: Boolean,
      default: false,
    },
    showIndicator: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'small'].includes(value),
    },
  },
  emits: ['visible-change'],
  setup(props, { emit, expose }) {
    const dropdown = ref(null);
    const popperClass = computed(() => `f-dropdown--content dropdown-popper ${props.blockClass}`);
    const isDropdownOpen = ref(false);

    const onHandleDropdown = () => {
      if (dropdown.value && props.hideOnClick) {
        dropdown.value.handleClose();
      }
    };

    const onForceHandleDropdown = () => {
      if (dropdown.value) {
        dropdown.value.handleClose();
      }
    };

    const onVisibleChange = (value) => {
      isDropdownOpen.value = value;
      emit('visible-change', value);
    };

    useKeyboardEvent(keys.BACKQUOTE, () => {
      onForceHandleDropdown();
    });

    expose({ onForceHandleDropdown });

    return {
      dropdown,
      onHandleDropdown,
      popperClass,
      isDropdownOpen,
      onVisibleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.f-dropdown {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: auto !important;
  @include transition-base (background-color);

  &:hover{
    cursor: pointer;
    background-color: var(--color-black-005);
  }

  &--title {
    padding: 8px 16px;
    color: var(--color-black);
    display: flex;
    @include focus-visible;

    &.small {
      padding: 7px 12px 7px 16px;
    }
  }

  &.no-styles {
    .f-dropdown--title {
      padding: 0;
    }
    &:hover {
      background: none;
    }
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.arrow-inactive {
  transition: 0.2s transform;
  @include transition-base(transform);
}

.arrow-active {
  transition: 0.2s transform;
  transform: rotate(-180deg);
  @include transition-base(transform);
}
</style>
