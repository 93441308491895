<template>
  <div
    class="dropdown-item"
    :class="[
      noBorder ? 'no-border' : '',
      noPaddings ? '' : 'paddings',
      size,
      to ? 'has-link' : '',
    ]"
  >
    <template v-if="to">
      <router-link class="no-styles dropdown-link" :to="to">
        <slot />
      </router-link>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'DropdownItem',
  props: {
    noBorder: {
      type: Boolean,
      default: false,
    },
    noPaddings: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'regular',
      validate: (value) => ['large', 'regular', 'medium', 'small'].includes(value),
    },
  },
  setup(props) {
    const height = computed(() => {
      switch (props.size) {
        case 'large':
          return '48px';
        case 'regular':
          return '44px';
        case 'medium':
          return '40px';
        case 'small':
          return '36px';
        default:
          return '44px';
      }
    });

    return {
      height,
    };
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item {
  cursor: pointer;
  border-bottom: 1px solid var(--color-black-01);
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: v-bind('height');

  &.paddings {
    &:first-child {
      padding-top: 4px;
      height: calc(v-bind('height') + 4px) !important;
    }

    &:last-child {
      padding-bottom: 4px;
      height: calc(v-bind('height') + 4px) !important;
    }
  }

  &:last-child, &.no-border {
    border-bottom: none;
  }

  &:hover {
    background: #F7F7F7;
  }
}

.has-link {
  padding: 0;
}
.dropdown-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
}
</style>
