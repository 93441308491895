<template>
  <ElCheckbox
    v-bind="$attrs"
    :class="{
      'is-wrap': isWrap,
      'is-error': hasError,
      round: type === 'round',
    }"
  >
    <template v-for="(el, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </ElCheckbox>
</template>

<script>
import 'element-plus/theme-chalk/el-checkbox.css';
import { ElCheckbox } from 'element-plus';
import { defineComponent, onMounted } from 'vue';

import { useAutoFocus } from '@/composables/useAutoFocus';

export default defineComponent({
  name: 'FCheckbox',
  components: {
    ElCheckbox,
  },
  props: {
    isWrap: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'square',
      validator: (value) => ['square', 'round'].includes(value),
    },
  },
  setup(props, { attrs }) {
    onMounted(() => {
      if (attrs.autofocus || attrs.autofocus === '') {
        const inputElement = document.querySelector('.el-checkbox__original');
        useAutoFocus(inputElement);
      }
    });
    return {};
  },
});
</script>

<style scoped lang="scss">
:deep(.el-checkbox__label) {
  height: auto;
  font-size: 13px;
  line-height: 1.35;
  padding-left: 12px;
  color: var(--color-black) !important;
  position: relative;
  top: 1px;
}

:deep(.el-checkbox__inner) {
  width: 16px;
  height: 16px;
  box-sizing: border-box;

  &::after {
    left: 5px;
    top: 3px;
    height: 5px;
  }
}

.round {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--color-white);
  @include flex-center;

  :deep(.el-checkbox__inner) {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-width: 2px;
  }
}

.is-wrap {
  white-space: normal !important;
}

:global(.checkbox-hover:hover .el-checkbox__inner) {
  border: 1px solid var(--color-primary);
}

.is-error {
  :deep(.el-checkbox__inner) {
    border-color: var(--color-error);
  }
}

:deep(.el-checkbox__input.is-disabled) {
  .el-checkbox__inner {
    background: #E6E6E6;
    border-color: #E6E6E6;
  }
}
</style>
