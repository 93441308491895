<template>
  <!-- Not an ElementUI component -->
  <div
    class="f-card"
    :class="{
      [color]: color,
    }"
  >
    <div v-if="hasHeader" class="f-card-header">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useSlots } from '@/composables/useSlots';

export default defineComponent({
  name: 'FCard',
  props: {
    color: {
      type: String,
      default: '',
      validator(value) {
        return ['grey', ''].includes(value);
      },
    },
  },
  setup(props, { slots }) {
    const { hasSlotData: hasHeader } = useSlots({ slots, slotName: 'header' });
    return {
      hasHeader,
    };
  },
});
</script>

<style scoped lang="scss">
.f-card {
  border: 1px solid var(--color-black-01);
  border-radius: 4px;
  padding: 30px 40px;
  margin-bottom: 20px;
  &.grey {
    background-color: #f7f7f7;
  }
}

.f-card-header {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 20px;
}
</style>
